import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import history from '../../history'
import activeRenderImageIdxReducer from './activeRenderImgIdxReducer'
import audiExclusiveDataStateReducer from './audiExclusiveDataStateReducer'
import carlineDataReducer from './carlineDataReducer'
import carlineSelectionReducer from './carlineSelectionReducer'
import cartReducer from './cartReducer'
import configurationDataReducer from './configurationDataReducer'
import efficiencyDataReducer from './efficiencyDataReducer'
import entryDataReducer from './entryDataReducer'
import equipmentFilterReducer from './equipmentFilterReducer'
import equipmentGroupsReducer from './equipmentGroupsReducer'
import equipmentHaveAudiExclusiveReducer from './equipmentHaveAudiExclusiveReducer'
import excludeAudiExclusiveSelectionReducer from './excludeAudiExclusiveSelectionReducer'
import failedRequestsReducer from './failedRequestsReducer'
import filtersReducer from './filtersReducer'
import infoModalReducer from './infoModalReducer'
import initParametersReducer from './initParametersReducer'
import languageIsLoadedReducer from './languageIsLoadedReducer'
import modelDataReducer from './modelDataReducer'
import notificationsReducer from './notificationsReducer'
import pendingRequestsReducer from './pendingRequestsReducer'
import purchaseModeReducer from './purchaseModeReducer'
import summaryReducer from './summaryReducer'
import technicalDataReducer from './technicalDataReducer'
import trackingIsReadyReducer from './trackingIsReadyReducer'
import trackingReducer from './trackingReducer'
import uiReducer from './uiReducer'
import vehicleCodeReducer from './vehicleCodeReducer'
import vehicleCodeStatusReducer from './vehicleCodeStatusReducer'
import visualizationReducer from './visualizationReducer'
import digitalGiveawayLinkReducer from './digitalGiveawayLinkReducer'
import navigateToTargetReducer from './navigateToTargetReducer'
import currentBuidabilityTypeReducer from './currentBuildabilityTypeReducer'

const rootReducer = combineReducers({
    router: connectRouter(history),
    activeRenderImageIdx: activeRenderImageIdxReducer,
    carlineData: carlineDataReducer,
    carlineSelection: carlineSelectionReducer,
    cart: cartReducer,
    configurationData: configurationDataReducer,
    efficiencyData: efficiencyDataReducer,
    entryData: entryDataReducer,
    equipmentFilter: equipmentFilterReducer,
    equipmentGroups: equipmentGroupsReducer,
    failedRequests: failedRequestsReducer,
    filters: filtersReducer,
    infoModal: infoModalReducer,
    initParameters: initParametersReducer,
    languageIsLoaded: languageIsLoadedReducer,
    modelData: modelDataReducer,
    notifications: notificationsReducer,
    summary: summaryReducer,
    pendingRequests: pendingRequestsReducer,
    purchaseMode: purchaseModeReducer,
    technicalData: technicalDataReducer,
    tracking: trackingReducer,
    trackingIsReady: trackingIsReadyReducer,
    ui: uiReducer,
    vehicleCode: vehicleCodeReducer,
    visualization: visualizationReducer,
    audiExclusiveAppDataState: audiExclusiveDataStateReducer,
    equipmentHaveAudiExclusive: equipmentHaveAudiExclusiveReducer,
    excludeAudiExclusiveSelection: excludeAudiExclusiveSelectionReducer,
    vehicleCodeStatus: vehicleCodeStatusReducer,
    digitalGiveawayLink: digitalGiveawayLinkReducer,
    shouldNavigateToTarget: navigateToTargetReducer,
    currentBuildabilityType: currentBuidabilityTypeReducer,
})

export type State = ReturnType<typeof rootReducer>

export default rootReducer
